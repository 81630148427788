import React from "react"
import StudiImage from "../images/Tata_Studi_Open_logo.svg"
import apiconfig from "../../config/apiconfig"
import sfdcConfig from "../../config/sfdcConfig"
import { AlertMessage } from "./alert"
import DatalayerEvents from "../../services/datalayer"
class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: 0,
      datalayerEvents: new DatalayerEvents(),
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.formValidation = this.formValidation.bind(this)
    this.sendOTP = this.sendOTP.bind(this)
  }

  formValidation = function (fullName, email, contact, grade, otp) {
    let nameRegex = new RegExp(/^[a-zA-Z ]*$/)
    let emailRegex = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
    let contactRegex = new RegExp(/^[6-9]\d{9}$/)
    let otpRegex = new RegExp(/^\d{4}$/)
    let flag = false

    if (
      fullName &&
      nameRegex.test(fullName) &&
      email &&
      emailRegex.test(email) &&
      contact &&
      contactRegex.test(contact) &&
      otp &&
      otpRegex.test(otp) &&
      grade
    ) {
      flag = true
    }
    return flag
  }

  handleSubmit = function (event) {
    event.preventDefault()
    document.getElementById("submit-btn").disabled = true
    const data = new FormData(event.target)
    if (data.get("radioBtn")) {
      let fullName = data.get("fullname")
      let email = data.get("email")
      let contact = data.get("mobnumber")
      let grade = data.get("grade")
      let otp = data.get("otp")
      let time = data.get("time")

      if (this.formValidation(fullName, email, contact, grade, otp)) {
        // ----------------------------------------------

        let data = {
          txtname: fullName,
          txtemail: email,
          txtcontact: contact,
          grade: grade,
          btn_submit: "true",
          txtotp: otp,
          time: "NA",
          source: "web",
        }

        var myHeaders = new Headers()
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

        var urlencoded = new URLSearchParams()

        for (let key in data) {
          urlencoded.append(key, data[key])
        }

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow",
        }

        fetch(apiconfig.FORMSAPI, requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result["code"] == 200) {
              //USING THE AUTH TOKEN AND PUSHING LEAD TO SFDC
              let authToken = result["token"]

              var SFDCHeaders = new Headers()
              SFDCHeaders.append("Authorization", "Bearer " + authToken)
              SFDCHeaders.append("Content-Type", "application/json")

              let newData = [
                {
                  Lastname: fullName,
                  Email: email,
                  Phone: contact,
                  leadsource: "web",
                  Grade: grade,
                  PreferredtimetoCall: time,
                },
              ]

              var userData = JSON.stringify({
                datalist: newData,
              })

              var SFDCrequestOptions = {
                method: "POST",
                headers: SFDCHeaders,
                body: userData,
                redirect: "follow",
                mode: "cors",
              }

              fetch(sfdcConfig.LEADURL, SFDCrequestOptions)
                .then(response => {
                  return { res: response.json(), status: response.status }
                })
                .then(result => {
                  if (result.status == 200) {
                    this.setState({ status: 200 })
                    document.getElementById("contactFormDesktop").reset()
                  }
                })
                .catch(error => {
                  console.log(error)
                  document.getElementById("submit-btn").disabled = false
                })
            } else if (result["code"] == 400) {
              document.getElementById("submit-btn").disabled = false
              this.setState({ status: 400 })
            } else {
              this.setState({ status: 500 })
              document.getElementById("submit-btn").disabled = false
            }
          })
          .catch(error => {
            console.log(error)
            document.getElementById("submit-btn").disabled = false
          })
      } else {
        document.getElementById("submit-btn").disabled = false
        alert("Please fill all the fields accurately")
      }
    } else {
      document.getElementById("submit-btn").disabled = false
      alert("Please Accept the T&C")
    }
  }

  sendOTP = function () {
    let mob = document.getElementById("mobnumber").value

    let otpHeaders = new Headers()
    otpHeaders.append("Content-Type", "application/x-www-form-urlencoded")

    let otpurlencoded = new URLSearchParams()
    otpurlencoded.append("action", "send_otp")
    otpurlencoded.append("contact_no", mob)

    fetch(apiconfig.OTPAPI, {
      method: "POST",
      headers: otpHeaders,
      body: otpurlencoded,
      redirect: "follow",
    })
      .then(response => response.json())
      .then(result => {
        document.getElementById("clear").style.visibility = "hidden"
        setTimeout(() => {
          document.getElementById("clear").style.visibility = "visible"
        }, 20000)
      })
      .catch(error => console.log("error", error))
  }

  componentDidMount() {
    let radioState

    document
      .getElementsByClassName("imCheckedDesktop")[0]
      .addEventListener("click", function (e) {
        if (radioState === this) {
          this.checked = false
          radioState = null
        } else {
          radioState = this
        }
      })
  }

  render() {
    return (
      <div id="contact-us-form" className="bg-white w-100">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              <div className="contact-experience-card">
                <div className="content_center">
                  <div id="contact-card-home-page">
                    <span className="cf-image-text">
                      Experience{" "}
                      <img
                        src={StudiImage}
                        className="studi-logo-form"
                        alt="Image"
                      />
                    </span>
                  </div>
                  <div>
                    <a
                      href="https://learn.tatastudi.com/registration.html"
                      rel="noopener noreferrer"
                    >
                      <button className="cf_singup_btn mt-md-4">Sign Up</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-8 px-0 mt-md-0 mt-5">
              <form id="contactFormDesktop" onSubmit={this.handleSubmit}>
                <div className="cf_get_in_touch_label">
                  <span className="get_in_touch">Get in touch with us... </span>

                  <div className="row mx-4 mt-md-2 no-gutters">
                    <div className="col-sm-12 col-md-6 pr-md-2">
                      <input
                        type="text"
                        id="fullname"
                        className="style_input"
                        name="fullname"
                        pattern="^[A-Za-z0-9 ]+"
                        title="Only alphabets and numbers are allowed"
                        placeholder="Full Name*"
                        maxLength="25"
                        required
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <input
                        type="email"
                        id="email"
                        className="style_input"
                        name="email"
                        placeholder="Email Address*"
                        required
                      />
                    </div>
                  </div>
                  <div className="row mx-4 my-md-2 no-gutters">
                    <div className="col-sm-12 col-md-3 mt-md-1 pr-md-2">
                      <div>
                        <select
                          id="grade"
                          name="grade"
                          className=" select_form select_desktop 
                            contact_form_grade_color"
                          required
                        >
                          <option value="" selected disabled hidden>
                            Grade{" "}
                          </option>
                          <option value="Class 1">Grade 1</option>
                          <option value="Class 2">Grade 2</option>
                          <option value="Class 3">Grade 3</option>
                          <option value="Class 4">Grade 4</option>
                          <option value="Class 5">Grade 5</option>
                          <option value="Class 6">Grade 6</option>
                          <option value="Class 7">Grade 7</option>
                          <option value="Class 8">Grade 8</option>
                          <option value="Class 9">Grade 9</option>
                          <option value="Class 10">Grade 10</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 mt-md-1 pr-md-2">
                      <input
                        type="text"
                        id="mobnumber"
                        name="mobnumber"
                        pattern="^[56789].+[0-9]+"
                        title="Number should start with 5, 6,7,8 or 9 
                          and length 10"
                        minLength="10"
                        maxLength="10"
                        className="style_input"
                        placeholder="Mobile No.*"
                        required
                      />
                      <button
                        type="button"
                        id="clear"
                        className="cf_otp_btn"
                        onClick={this.sendOTP}
                      >
                        {" "}
                        &nbsp;Get Otp&nbsp;{" "}
                      </button>
                    </div>
                    <div className="col-sm-12 col-md-3 mt-md-1">
                      <input
                        type="text"
                        id="otp"
                        className="style_input"
                        name="otp"
                        minLength="4"
                        maxLength="4"
                        pattern="[0-9]+"
                        title="Please Enter correct OTP"
                        placeholder="OTP Verification"
                        required
                      />
                    </div>

                    {/* <div className="col-sm-12 col-md-3 mt-md-1 pr-md-2">
                      <select
                        id="time"
                        name="time"
                        className=" select_form select_desktop 
                            contact_form_grade_color  ml-md-2"
                        required
                      >
                        <option value="" selected disabled hidden>
                          Preffered time to call{" "}
                        </option>
                        <option value="08AM-10AM">08AM-10AM</option>
                        <option value="10AM-12PM">10AM-12PM</option>
                        <option value="12PM-02PM">12PM-02PM</option>
                        <option value="02PM-04PM">02PM-04PM</option>
                        <option value="04PM-06PM">04PM-06PM</option>
                        <option value="06PM-09PM">06PM-09PM</option>
                      </select>
                    </div> */}
                  </div>
                  {/* </div> */}

                  <div className="form-check justify-content-center mt-3">
                    <input
                      className="form-check-input imCheckedDesktop"
                      type="radio"
                      name="radioBtn"
                      id="desktop_home_radio"
                      required
                      title="Please select the T&Cs"
                    />
                    <label
                      className="form-check-label cf_accept_label"
                      htmlFor="desktop_home_radio"
                    >
                      I Accept the
                      <a
                        className="cf_accept_label"
                        id="terms"
                        href="/termsandconditions/"
                        target="_blank"
                      >
                        <span className="t_and_c">T&amp;Cs</span>
                      </a>
                    </label>
                  </div>
                  <div>
                    <input
                      id="submit-btn"
                      type="submit"
                      className="send_btn"
                      value="Send"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div>
          {(() => {
            if (this.state.status == 200) {
              return (
                <AlertMessage
                  variant="alert-success"
                  message="Success!"
                  classes="col-xs-10 col-md-3"
                />
              )
            } else if (this.state.status == 400) {
              return (
                <AlertMessage
                  variant="alert-danger"
                  message="Some Error Occured! Please try again."
                  classes="col-xs-10 col-md-3"
                />
              )
            } else if (this.state.status == 500) {
              return (
                <AlertMessage
                  variant="alert-danger"
                  message="Not Verified, Please try again."
                  classes="col-xs-10 col-md-3"
                />
              )
            } else {
              return <br />
            }
          })()}
        </div>
      </div>
    )
  }
}

export default ContactForm
