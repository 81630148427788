import React, { useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import ReactPlayer from "react-player/lazy"

   class PhotoVideoCopy extends React.Component {
   render() {
  return (
    <div id="how_it_works" className="how_it_works container-fluid">
      <div className="photo_video_heading text-center">
        <p>
          {" "}
          How&nbsp;
          <span className="heading-blue">It Works</span>
        </p>
      </div>

      <div className="row photo_video_row11">
        <div className="col-xs-12 col-md-6 justify-content-center mb-4 photo_video_custom_class">
          <center>
            <StaticImage
              src="../images/howitworks.png"
              layout="fullWidth"
              placeholder="tracedSVG"
              alt="How Tata Studi Works"
              title="How Tata Studi Works"
              loading="lazy"
            />
          </center>
        </div>

        <div className="col-xs-12 col-md-6 mx-auto">
          <div className="player-wrapper rounded-lg">
            <ReactPlayer
              className="react-player"
              url="https://youtu.be/f9AAppwVxsE"
              width="100%"
              height="100%"
              controls
              playing
              light="https://i.ytimg.com/vi/f9AAppwVxsE/sddefault.jpg"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
}

export default PhotoVideoCopy
