import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

class ProductFeatures extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active_box: 0,
    }
  }
  toggleActiveBox(index) {
    if (this.state.active_box == index) {
      this.setState({ active_box: 0 })
    } else {
      this.setState({ active_box: index })
    }
  }
  componentDidMount() {
    let trimText = document.getElementsByClassName(
      "product_feature_trimmed_text_first"
    )
    for (var i = 0; i < trimText.length; i++) {
      let prestr = trimText[i].textContent.substring(0, 0)
      trimText[i].textContent = prestr
    }

    let trimTextNext = document.getElementsByClassName(
      "product_feature_trimmed_text_second"
    )
    for (var i = 0; i < trimTextNext.length; i++) {
      let poststr = trimTextNext[i].textContent
      let prestr = trimTextNext[i].textContent.substring(0, poststr.length)
      trimTextNext[i].textContent = prestr
    }
  }

  static propTypes = {
    data: PropTypes.object.isRequired,
  }

  render() {
    return (
      <div id="why_studi">
        <div className="product_feature_back">
          <div className="prd_margin_top_thirtyfive">
            <div className="row">
              {this.props.data.nodes.map((item, i) => {
                i = i + 1
                return (
                  <div className="col-sm-6 col-lg-3 col-xs-12 mainDiv" key={i}>
                    <div
                      id={"box_" + i}
                      className={
                        this.state.active_box == i ? "div2 active-box" : "div2"
                      }
                    >
                      <center>
                        <GatsbyImage
                          image={
                            item.relationships.field_product_features_image
                              .localFile.childImageSharp.gatsbyImageData
                          }
                          className="prd_feature_back"
                          alt="Product Feature"
                          title="Product Feature"
                          loading="lazy"
                          placeholder="tracedSVG"
                          quality="10"
                          formats={["webp"]}
                        />
                      </center>

                      <div className="spanDiv">
                        <span
                          className="feature_label"
                          dangerouslySetInnerHTML={{
                            __html:
                              item.field_product_features_heading.processed,
                          }}
                        />
                      </div>

                      <div className="prd_margin_top_mob product_feature_trimmed_text_first">
                        <center>
                          <span
                            className="feature_sub_label"
                            dangerouslySetInnerHTML={{
                              __html:
                                item.field_product_features_body_text.processed,
                            }}
                          />
                        </center>
                      </div>

                      <div className="product_feature_fa_class">
                        <i
                          className={
                            this.state.active_box == i
                              ? "fa fa-minus-circle prd_plus"
                              : "fa fa-plus-circle prd_plus"
                          }
                          id={"drop_down_plus_" + i}
                          aria-hidden="true"
                          data-toggle="collapse"
                          data-target={"#demo_" + i}
                          onClick={() => this.toggleActiveBox(i)}
                        />
                        <p className="prd_plus_line" />
                      </div>

                      <div
                        id={"demo_" + i}
                        className={
                          this.state.active_box == i ? "show" : "collapse"
                        }
                      >
                        <span
                          className="feature_sub_label_mob product_feature_trimmed_text_second"
                          dangerouslySetInnerHTML={{
                            __html:
                              item.field_product_features_body_text.processed,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ProductFeatures
